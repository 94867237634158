import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import axios from 'axios'
import authModule from './store/authenticator'
import { Client as ConversationsClient } from "@twilio/conversations";
Vue.config.productionTip = false
import { TABLE, MESSAGE } from "./store/ModuleName";
import { EVENTS_NAMESPACE, MUTATIONS, ACTIONS } from "./store/Modules/Events/types-events";
import { ADMIN_DASHBOARD_NAMESPACE, MUTATIONS as ADMIN_DASHBOARD_MUTATIONS, ACTIONS as ADMIN_DASHBOARD_ACTIONS } from "./store/Modules/AdminDashboard/types-admin-dashboard";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js"; // dependent on utc plugin
import { sendNotification } from "./utils/DeviceFeature";
// import * as msal from "@azure/msal-browser";
import VCalendar from 'v-calendar';
var weekOfYear = require('dayjs/plugin/weekOfYear')



dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Singapore");
dayjs.extend(weekOfYear)

import vSelect from 'vue-select'

// import AdminDashBoard from './views/admin-homepage/AdminDashBoard.vue'
import AdminDashBoard from './views/admin-homepage/AdminDashBoard'
import UpcomingAssessment from './views/admin-homepage/UpcomingAssessment.vue'
import ListAppointment from './views/admin-homepage/ListAppointment.vue'
import ListClient from './views/admin-homepage/ListClient.vue'
import WeeklySchedule from './views/admin-homepage/WeeklySchedule.vue'
import MasterSchedule from './views/admin-homepage/MasterSchedule.vue'

Vue.component('ad-dashboard', AdminDashBoard)
Vue.component('upcoming-assessment', UpcomingAssessment)
Vue.component('list-appointment', ListAppointment)
Vue.component('list-client', ListClient)
Vue.component('weekly-schedule', WeeklySchedule)
Vue.component('master-schedule', MasterSchedule)


Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
Vue.use(VCalendar, {
  componentPrefix: 'v',
});
if (Notification.permission === 'default') {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.warn('Notification permission denied.');
    }
  });
}
new Vue({

  render: h => h(App),
  async created() {
    try {
      // let apiResult = axios.create({
      //   baseURL: `${location.origin}/api`
      // })
  
      // this.$store.commit('api', apiResult);
      // console.log('running init main')
      await authModule.init();
      // const { data } = await this.$store.state.api.get('/chat');
      // await this.initChat(data.token);
      
    } catch (error) {
      console.error(error);
    }

  },
  async mounted() {

    try {
      
      while(!this.$store.state.api){
        await new Promise(resolve => {
          const interval = setInterval(() => {
            // console.log('checking if api ready')
            if(this.$store.state.api){
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }

      this.$store.commit('modifyApi');
      if(this.$route.meta?.adminDashboard && !this.$store.state.loadedAdminDashboard){
        await this.initAdminDashboardData();
        this.$store.commit('setLoadedAdminDashboard', true);
      }

      const { data } = await this.$store.state.api.get('/every_client');
      // const { data: data2 } = await this.$store.state.api.get('/chat');
      // await this.initChat(data2.token);
      this.cli_list = data?.cli_list || [];
      this.cg_list = data?.cg_list || [];
      // this.clearAnnouncements();

      //--- set admin dashboard data
      // await this.initAdminDashboardData();

      await this.modifyClientAndCaregiverListData();
      
      
      // await new Promise(r => setTimeout(r, 7000));
      // this.countNewMessageCaregiver(this.admin);

    } catch (error) {
      console.error(error);
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

  },
  data() {
    return {
      dayjs,
      cli_list: [],
      cg_list: [],
      conversationsClient: null,
      status: null,
      state: {
        name: "",
        loggedIn: "",
        token: null,
        statusString: null,
        conversationsReady: false,
        conversations: [],
        selectedConversationSid: null,
        newMessage: "",
      },
      everyoneAnnouncementRoom: "Everyone|Announcement",
      announcements: [],
      announcementsLoading: true,
      logindata: { ready: false },
      isAdmin: false,
      msalApp: null,
      promptLogin: false,
      accounts: null,
    }
  },
  computed: {
    ...mapState({
      listData: state => (state[EVENTS_NAMESPACE].listData),
      admin: state => (state[MESSAGE].contactList.admin),
    }),
    ...mapGetters(TABLE, ["getAnnouncementData"]),
    ...mapGetters(MESSAGE, ["getAdminTotalCountMessage", "getAdminMessage"]),
  },
  methods: {
    // async login() {
    //   await authenticate.login();
    //   // try {
    //   //   await this.msalApp.loginRedirect({
    //   //     scopes: [`https://${this.logindata.DATAVERSE_DOMAIN}/.default`],
    //   //     redirectUri: location.origin + "/home",
    //   //   });

    //   //   if (!this.$store.state.msalApp && this.msalApp) {
    //   //     this.$store.commit("msalApp", this.msalApp)
    //   //     this.$store.commit("isAdmin",true);
    //   //   } else {
    //   //     console.log("not");
    //   //   }
    //   //   console.log("DONE")
    //   //   await this.$root.prepareAxios();

    //   //   this.promptLogin = false;
    //   // } catch (e) {
    //   //   console.log(e);
    //   // }
    // },
    // async init() {
    //   await authenticate.init();
    //   // return axios.get("/api/init").then(async (rsp) => {
    //   //   this.logindata = rsp.data;
    //   //   this.$store.commit("logindata", rsp.data);
    //   //   const msalConfig = {
    //   //     auth: {
    //   //       clientId: this.logindata.MS_APP_ID,
    //   //       authority: `https://login.microsoftonline.com/${this.logindata.TENANT_ID}`,
    //   //     },
    //   //   };
    //   //   console.log(msalConfig, 'confff');
    //   //   let msalApp = (this.msalApp = new msal.PublicClientApplication(
    //   //     msalConfig
    //   //   ));
    //   //   let accounts = msalApp.getAllAccounts();
    //   //   console.log(accounts);
    //   //   if (accounts.length > 0) {
    //   //     msalApp.setActiveAccount(accounts[0]);
    //   //     this.$store.commit("msalApp", msalApp);
    //   //     this.$store.commit("accounts",accounts)
    //   //     this.$store.commit("isAdmin",true);
    //   //     await this.$root.prepareAxios();
          
    //   //   } else {
    //   //     msalApp
    //   //       .handleRedirectPromise()
    //   //       .then(async () => {
    //   //         // Check if user signed in
    //   //         const account = msalApp.getActiveAccount();
    //   //         this.$store.commit("msalApp", msalApp);
    //   //         this.$store.commit("isAdmin",true);
    //   //         await this.$root.prepareAxios();
    //   //         if (!account) {
    //   //           this.$root.login();
    //   //         }
    //   //       })
    //   //       .catch(() => {
    //   //         this.$root.login();
    //   //       });
    //   //     this.$root.login();
    //   //   }

    //   //   return rsp;
    //   // });
    // },
    ...mapMutations(["setIsLoadingTwilio"]),
    ...mapMutations(TABLE, ["addAnnouncements", "clearAnnouncements"]),
    ...mapMutations(MESSAGE, ["setAdminContactList", "sendAdminMessage", "setCountTotalCaregiverNewMessage"]),
    ...mapMutations(EVENTS_NAMESPACE, [
      MUTATIONS.ADD_LIST_DATA,
      MUTATIONS.REMOVE_EVENT_LIST_DATA,
      MUTATIONS.MODIFY_ITEM_LIST_DATA,
      MUTATIONS.SET_LIST_LUNCH_DATA_FROM_LIST_DATA,
      MUTATIONS.SET_LIST_CLIENT_AND_CAREGIVER_DATA,
    ]),
    ...mapMutations(ADMIN_DASHBOARD_NAMESPACE, [
      ADMIN_DASHBOARD_MUTATIONS.SET_IS_FETCHING_ADMIN_DASHBOARD_DATA,
    ]),
    ...mapActions(EVENTS_NAMESPACE, [
      ACTIONS.VALIDATE_LIST_DATA,
      ACTIONS.DETERMINE_EVENT_STATUS,
      ACTIONS.DB_POST_EVENT,
      ACTIONS.DB_DATA_DELETE_EVENT,
    ]),
    ...mapActions(ADMIN_DASHBOARD_NAMESPACE, [
      ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_NEW_CLIENT_REGISTERED_TODAY,
      ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_ASSESSMENT,
      ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_WEEKLY_SCHEDULE,
      ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_MASTER_SCHEDULE,
      ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_CLIENT,
      ADMIN_DASHBOARD_ACTIONS.DB_GET_APPOINTMENT,
    ]),
    async initAdminDashboardData(){
      this[ADMIN_DASHBOARD_MUTATIONS.SET_IS_FETCHING_ADMIN_DASHBOARD_DATA](true);
      const response = await Promise.allSettled([
        // this[ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_NEW_CLIENT_REGISTERED_TODAY](),
        this[ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_ASSESSMENT](),
        this[ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_MASTER_SCHEDULE](),
        this[ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_CLIENT](),
        this[ADMIN_DASHBOARD_ACTIONS.DB_GET_APPOINTMENT](),
        this[ADMIN_DASHBOARD_ACTIONS.DB_GET_LIST_WEEKLY_SCHEDULE](),
      ]);

      // if(response[0].status === "rejected"){
      //   console.error("Error getting list new client registered today"); 
      // }
      if(response[0].status === "rejected"){
        console.error("Error getting list assessment"); 
      }
      if(response[1].status === "rejected"){
        console.error("Error getting list master schedule"); 
      }
      if(response[2].status === "rejected"){
        console.error("Error getting list client"); 
      }
      if(response[3].status === "rejected"){
        console.error("Error getting list appointment"); 
      }
      if(response[4].status === "rejected"){
        console.error("Error getting list weekly schedule"); 
      }


      this[ADMIN_DASHBOARD_MUTATIONS.SET_IS_FETCHING_ADMIN_DASHBOARD_DATA](false);

    },
    async getEveryClient(){
      try {
        const { data } = await this.$store.state.api.get('/every_client');
        return data.cgLinks;

      } catch (error) {
        console.error(error);
        this.makeToast("Error", "Error fetching client data", "danger");
      }
      

    },
    async modifyClientAndCaregiverListData(){
      const clientAndCaregiverData = await this.$root.getEveryClient();
      const mapClientAndCaregiverData = clientAndCaregiverData
      .filter(item => item.crb5c_Client?.crb5c_client_status === 411620000)
      .map(item => ({
          crb5c_Caregiver: {
              crb5c_fow_caregiverid: item.crb5c_Caregiver?.crb5c_fow_caregiverid ?? null ,
              crb5c_name: item.crb5c_Caregiver?.crb5c_name ?? null,
          },
          crb5c_Client: {
              crb5c_fow_customerid: item.crb5c_Client?.crb5c_fow_customerid,
              crb5c_no: item.crb5c_Client?.crb5c_no,
          },
      }))
      .filter(item => item.crb5c_Caregiver.crb5c_fow_caregiverid !== null);


      const uniqueListCaregiverAndClientData = mapClientAndCaregiverData.filter((obj, index, self) => {
          return index === self.findIndex((o) => o.crb5c_Client.crb5c_fow_customerid === obj.crb5c_Client.crb5c_fow_customerid);
      });

      //console.log("uniqueListCaregiverAndClientData: ", uniqueListCaregiverAndClientData);
      
      uniqueListCaregiverAndClientData.sort((a, b) => (a.crb5c_Client.crb5c_no || '').localeCompare(b.crb5c_Client.crb5c_no || ''));
      this[MUTATIONS.SET_LIST_CLIENT_AND_CAREGIVER_DATA](uniqueListCaregiverAndClientData);

    },
    countNewMessageCaregiver(adminData){
      // FIX: the notification count is not correct
      const newValue = [];

      console.log("adminData: ", adminData);

      for(let item of adminData){
        const uniqueName = item.id.split('|');
        const authorId = `${uniqueName[0]}|${uniqueName[1]}`;
        const listLastChatIsCaregiver = [];

        if(item.message.length !== 0){
          const listAuthor = item.message.map(item => item.stateChat);

          // console.log("listAuthor: ", listAuthor);

          for(let i=listAuthor.length; i>0; i--){
            if(listAuthor[i]){
              if(listAuthor[i] === authorId){
                listLastChatIsCaregiver.push(listAuthor[i]);
                continue;
              }
  
              if(listAuthor[i] !== authorId){
                break;
              }
            }

          }

        }

        const chat = {
          ...item,
          countNewMessage: listLastChatIsCaregiver.length
        }

        newValue.push(chat);
        
        // console.log("newValue: ", newValue);
        // console.log("length listLastChatIsCaregiver: ", listLastChatIsCaregiver.length);
        // console.log("listLastChatIsCaregiver: ", listLastChatIsCaregiver);

        const newMessageCount = listLastChatIsCaregiver.length;

        this["setCountTotalCaregiverNewMessage"](newMessageCount);

      }
    },
    nameLookup(id) {
      let cName = this.cli_list.find(c => c.crb5c_fow_customerid === id.split('|')[1])?.crb5c_no || id;
      let cgName = this.cg_list.find(c => c.crb5c_fow_caregiverid === id.split('|')[0])?.crb5c_name || id;
      return `Caregiver ${cgName} for ${cName}`;

    },
    photoLookup(id) {
      return this.cli_list.find(c => c.crb5c_fow_customerid === id.split('|')[1])?.crb5c_photo || null;

    },
    onConnectionStateChanged(conversationsClient){
      conversationsClient.on("connectionStateChanged", (state) => {
        if (state === "connecting") {
          this.statusString = "Connecting to Twilio…";
          this.status = "default";
        }
        if (state === "connected") {
          this.statusString = "You are connected.";
          this.status = "success";
        }
        if (state === "disconnecting") {
          this.statusString = "Disconnecting from Twilio…";
          this.conversationsReady = false;
          this.status = "default";
        }
        if (state === "disconnected") {
          this.statusString = "Disconnected.";
          this.conversationsReady = false;
          this.status = "warning";
        }
        if (state === "denied") {
          this.statusString = "Failed to connect.";
          this.conversationsReady = false;
          this.status = "error";
        }
      });
    },
    async onConversationJoined(conversationsClient){
      conversationsClient.on(
        "conversationJoined",
        async (conversation) => {
          this.setIsLoadingTwilio(false);
          this.conversations = [...this.state.conversations, conversation];

          // console.log("JOINED A NEW ONE", conversation);
          
          // if (conversation.uniqueName.includes('|Announcement')) {
          //   let messageObj = await conversation.getMessages();
          //   for (let m of messageObj.items) {

          //     // this.announcements.push(m.state);
          //     //commit
          //     const { sid: id, body: message, dateUpdated } = m.state;

          //     // console.log("Attributes: ", m.state.attributes);
          //     const { title, eventDate, eventTime, isEvent, isEventConversation, groupName } = m.state.attributes;

          //     // Channel Id
          //     // console.log(`Channel Id for ${groupName}: `, m.conversation.sid);

          //     if(isEventConversation){
          //       // push event data to event vuex
          //       const attributes = m.state.attributes;
          //       const payload = {
          //         ...attributes,
          //         msgId: id,
          //         convId: m.conversation.uniqueName,
          //         status: await this[ACTIONS.DETERMINE_EVENT_STATUS](attributes.date),
          //         description: message,
          //         // media: null,
          //         // gst: 1.08,
          //         // otherPersonJoining: [],
          //         // typeOfPayment: "online",
          //         // paymentStatus: false 
          //       }

          //       await this[ACTIONS.VALIDATE_LIST_DATA](payload);
          //       this[MUTATIONS.ADD_LIST_DATA](payload);
          //     } 
          //     else{
          //       const dataAnnouncements = {
          //         id,
          //         title,
          //         message,
          //         dateUpdated,
          //         options: "", // Default
          //         eventDate,
          //         eventTime,
          //         isEvent,
          //         convId: m.conversation.uniqueName,
          //         groupName,
          //         recipients: groupName ? groupName: "Everyone", // Default
          //       }
  
  
          //       this.announcements.push(dataAnnouncements);
  
          //       await this.addAnnouncements(dataAnnouncements);
          //     }

          //   }
          // }

          //
          if (conversation.uniqueName.includes('|Chat')) {
            
            const messages = [];
            //let totalMessages = 0;

            let receivedMessagePaginator =(messagePaginator) => {
              //console.log("PAGINATOR", messagePaginator)
              //totalMessages += messagePaginator.items.length;
              messagePaginator.items.forEach(function (message) {

                messages.push(message);
              });
              // if (messagePaginator.hasNextPage) {
              //   messagePaginator.nextPage().then(receivedMessagePaginator);
              // } 

              // if (messagePaginator.hasPrevPage) {
              //   messagePaginator.prevPage().then(receivedMessagePaginator);
              // }
              //   console.log("Total Messages:" + totalMessages);
              
            }

            let messagez = await conversation.getMessages()
            receivedMessagePaginator(messagez);

            let name = this.nameLookup(conversation.uniqueName)
            let photo = this.photoLookup(conversation.uniqueName);
            
            const uniqueName = conversation.uniqueName.split('|');
            const authorId = `${uniqueName[0]}|${uniqueName[1]}`;
            const listAuthor = messages.map(item => item.author);
            const listLastChatIsCaregiver = [];
            
            for(let i=listAuthor.length; i>0; i--){
              if(listAuthor[i]){
                if(listAuthor[i] === authorId){
                  listLastChatIsCaregiver.push(listAuthor[i]);
                  continue;
                }
                if(listAuthor[i] !== authorId){
                  break;
                }
              }
  
            }

            const chat = {
              id: conversation.uniqueName,
              name: name,
              photo,
              // eslint-disable-next-line no-unused-vars
              message: messages.map(m => ({
                    time: m.state.timestamp,
                    stateChat: m.author,
                    message: m.state.body,
                    id: m.sid
              })),
              // // Todo: can map the chat
              // countNewMessage: listLastChatIsCaregiver.length
            }

            this.setAdminContactList(chat);

          }
        }
      );
    },
    onConversationLeft(conversationsClient){
      conversationsClient.on("conversationLeft", (thisConversation) => {
        this.conversations = [
          ...this.state.conversations.filter((it) => it !== thisConversation),
        ];
      });

    },
    async onMessageAdded(conversationsClient){
      conversationsClient.on("messageAdded", async (thisConversation) => {
        if (
          thisConversation.conversation.uniqueName ==
          this.everyoneAnnouncementRoom
        ) {
          this.announcements.push(thisConversation.state);
        }

        // if (thisConversation.conversation.uniqueName.includes('|Announcement')) {

        //   const convId = thisConversation.conversation.uniqueName;
        //   const { sid: id, body: message } = thisConversation.state;
        //   const { isEventConversation } = thisConversation.state.attributes;
          
        //   if(isEventConversation){
        //     // push event data to event vuex
        //     const attributes = thisConversation.state.attributes;
        //     const payload = {
        //       ...attributes,
        //       msgId: id,
        //       convId,
        //       status: await this[ACTIONS.DETERMINE_EVENT_STATUS](attributes.date),
        //       description: message,
        //     }

        //     await this[ACTIONS.VALIDATE_LIST_DATA](payload);
        //     this[MUTATIONS.ADD_LIST_DATA](payload);

        //     const { title, admin, listGroup, date, itinerary, lunch, pricePerPerson, gst } = attributes;
        //     const dataPostEvent = await this[ACTIONS.DB_POST_EVENT]({
        //         msgId: id,
        //         convId,
        //         adminName: admin,
        //         title,
        //         listGroup, 
        //         eventDate: date, 
        //         description: message,
        //         itinerary, 
        //         lunch, 
        //         pricePerPerson,
        //         gst
        //     });

        //     if(!dataPostEvent){
        //       console.error("Error save event to DB");
        //     }

        //   }else {
        //     const { title } = thisConversation.state.attributes;
        //     this.makeToast(title, "Send announcement success", "success");
        //   }

        // }

        if (thisConversation.conversation.uniqueName.includes('|Chat')) {
          const chatRoom = thisConversation.conversation.uniqueName;
          const m = thisConversation.state;
          // console.log("chat state: ", m);
          this.sendAdminMessage({
            id: chatRoom,
            message: {
              time: m.timestamp,
              stateChat: m.author,
              message: m.body,
              id: m.sid
            }
          });

          // eslint-disable-next-line no-unused-vars
          const [clientId, dsgId, room] = chatRoom.split("|");
          
          if(m.author.split("|")[0] === clientId){
            const clientName = this.nameLookup(thisConversation.conversation.uniqueName);
            this.makeToast(clientName, m.body, "success");
            console.log('clientName',clientName)
            sendNotification(m.body, clientName);
          }

        }
      });

    },
    onMessageUpdated(conversationsClient){
      conversationsClient.on("messageUpdated", async (conversation) => {
        
        const uniqueName = conversation.message.conversation._internalState.uniqueName;
        
        if (uniqueName.includes('|Announcement')) {
          const { sid: id, body: message } = conversation.message.state;
          const { isEventConversation } = conversation.message.state.attributes;
          
          if(isEventConversation){
            // push event data to event vuex
            const attributes = conversation.message.state.attributes;
            const payload = {
              ...attributes,
              msgId: id,
              convId: uniqueName,
              status: await this[ACTIONS.DETERMINE_EVENT_STATUS](attributes.date),
              description: message,
            }

            console.log("event update triggered: ", payload);
            
            await this[ACTIONS.VALIDATE_LIST_DATA](payload);
            this[MUTATIONS.MODIFY_ITEM_LIST_DATA](payload);
          }
        }

      });
    },
    onMessageRemoved(conversationsClient){
      conversationsClient.on("messageRemoved", (thisConversation) => {
        
        if (thisConversation.conversation.uniqueName.includes('|Announcement')) {
          const { sid: msgId } = thisConversation.state;
          const { isEventConversation } = thisConversation.state.attributes;

          if(isEventConversation){
            this[MUTATIONS.REMOVE_EVENT_LIST_DATA](msgId);
          }

          this.announcements = this.announcements.filter(
            (a) => a.sid !== thisConversation.state.sid
          );
        }

        
      });

    },
    async onTokenAboutToExpire(conversationsClient){
      conversationsClient.on("tokenAboutToExpire", async () => {
        const { data } = await this.$store.state.api.get('/chat');
        console.log("RENEWED")
        this.conversationsClient.updateToken(data.token);
      });

    },
    async onTokenExpired(conversationsClient){
      conversationsClient.on("tokenExpired", async () => {
        const { data } = await this.$store.state.api.get('/chat');
        console.log("EXPIRED, RENEWED")
        this.conversationsClient.updateToken(data.token);
      });

    },
    async initChat(token) {
      window.conversationsClient = ConversationsClient;
      this.conversationsClient = new ConversationsClient(token);

      this.statusString = "Connecting to Twilio…";
      this.setIsLoadingTwilio(true);
      this.onConnectionStateChanged(this.conversationsClient);

      // conversation
      await this.onConversationJoined(this.conversationsClient);
      this.onConversationLeft(this.conversationsClient);

      // message
      this.onMessageAdded(this.conversationsClient);
      this.onMessageUpdated(this.conversationsClient);
      this.onMessageRemoved(this.conversationsClient);

      // token
      await this.onTokenAboutToExpire(this.conversationsClient);
      await this.onTokenExpired(this.conversationsClient);

      let a = await this.conversationsClient.getSubscribedConversations();
      this.state.conversations = a.items;

    },
    // async prepareAxios() {
    //   let msalApp = this.$store.state.msalApp;

    //   if (!msalApp) {
    //     return;
    //   }

    //   let accounts = msalApp.getAllAccounts();
    //   if (accounts.length > 0) {
    //     msalApp.setActiveAccount(accounts[0]);
    //     this.$store.commit('msalApp', msalApp);
    //     localStorage.setItem('msalAccount', JSON.stringify(accounts[0]));
    //     this.$store.commit('currentAdmin', accounts[0]);
    //   }

    //   let { accessToken } = await msalApp.acquireTokenSilent({
    //     scopes: [
    //       `https://${this.$store.state.logindata.DATAVERSE_DOMAIN}/.default`
    //     ]
    //   });
    //   this.token = accessToken
    //   let result = axios.create({
    //     baseURL: `https://${this.$store.state.logindata.DATAVERSE_DOMAIN}/api/data/${this.$store.state.logindata.DATAVERSE_VERSION}`,
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json; charset=utf-8',
    //       'OData-MaxVersion': '4.0',
    //       'OData-Version': '4.0',
    //       'Authorization': 'Bearer ' + accessToken,
    //       'Prefer': 'return=representation'
    //     }
    //   });
    //   this.$store.commit('axios', result);

    // },
    makeToast(title, message, variant){
      this.$bvToast.toast(message, {
          title,
          variant,
          solid: true,
      });
    },
    sortSessionGroupNameByDay(listSessionGroup){
      const cloneListSessionGroup = [...listSessionGroup];
      cloneListSessionGroup.sort((a, b) => {
        const dayA = a.name[0].toUpperCase();
        const dayB = b.name[0].toUpperCase();
        if (dayA < dayB) {
          return -1;
        } else if (dayA > dayB) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }

      });

      return cloneListSessionGroup;

    },
    redirectToClientAssessment(clientId){    
      const clientURL = `https://fow-assessment.npo.sg/home/?client_id=${encodeURIComponent(clientId)}`;
      window.open(clientURL, '_blank');
    },
    redirectToClientPage(cliendId){
        const BASE_URL = `https://${this.$store.state?.logindata?.DATAVERSE_DOMAIN ?? 'dsg-fow.crm5.dynamics.com'}`;
        const clientURL = `${BASE_URL}/main.aspx?appid=d951a007-cf7a-ed11-81ac-000d3a85cc97&pagetype=entityrecord&etn=crb5c_fow_customer&id=${encodeURIComponent(cliendId)}`;

        window.open(clientURL, '_blank')
        console.log('client id - client page:', cliendId)
    },
  },
  watch: {
    listData(value){
      this[MUTATIONS.SET_LIST_LUNCH_DATA_FROM_LIST_DATA](value);
    },
    // // TODO: watch admin data from vuex
    // admin(value){
    //   this.countNewMessageCaregiver(value);

    // }
    // eslint-disable-next-line no-unused-vars
    async $route(to, from){
      if(to.meta?.adminDashboard && !this.$store.state.loadedAdminDashboard){
      await this.initAdminDashboardData();
      this.$store.commit('setLoadedAdminDashboard', true);
      }
    }
    
  },
  router,
  store,
}).$mount('#app')


