<template lang="pug">
div(class="w-100" v-if="!loading && event")
    div(class="d-flex flex-column header-details").m-0.p-0
        div(class="d-flex justify-content-between align-items-center").mt-2
            h5 {{ event.name }} 
            div(class="d-flex align-items-center" style="gap: 10px;")
                b-button(variant="outline-primary"  @click="showAddPeopleModal = true")
                    b-icon(icon="plus")
                b-button(variant="outline-primary" @click="showEditModal = true")
                    b-icon(icon="pencil")
                //- :disabled=" submissions.length > 0 || submissions.filter(submission => submission.status === 'Not Attending').length > 0")
                b-button(variant="outline-primary"  @click="downloadExcel" :disabled="downloading || submissions.length === 0")
                    b-icon(icon="printer" v-if="!downloading")
                    b-spinner(type="grow" variant="primary" v-else small)
                b-button(variant="outline-success" @click="refreshEvent")
                    svg(
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-rotate-ccw"
                        )
                        path(d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8")
                        path(d="M3 3v5h5")
                b-button(variant="outline-danger" @click="deleteEvent")
                    b-icon(icon="trash")
                
                
                //- b-button(variant="outline-danger" @click="showModalDetails = false")
                //-     b-icon(icon="trash")
        div(class="d-flex")
            span.badge(v-if="event.isSingleDay" :class="new Date(event.singleDay.date) > new Date() ? 'badge-primary' : 'badge-secondary'")  {{ new Date(event.singleDay.date) > new Date() ? 'Upcoming' : 'Past' }} {{ new Date(event.singleDay.date).toLocaleDateString() }}
            span.badge(v-else :class="new Date(event.multipleEvents[0].date) > new Date() ? 'badge-primary' : 'badge-secondary'")   {{ new Date(event.multipleEvents[0].date) > new Date() ? 'Upcoming' : 'Past' }} {{ new Date(event.multipleEvents[0].date).toLocaleDateString() }}
        div(class="d-flex flex-column align-items-start mt-3" v-if="showEventDetails")
            span.mt-2 Description:
            div(v-html="event.description" style="text-align: start;" class="mt-3")
        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;")
    div(class="d-flex flex-column event-details align-items-start").m-0.p-0
        div(class="d-flex flex-column align-items-start " v-if="showEventDetails")  
            h6.my-2 Event Details
            div(class="d-flex flex-column align-items-start ")
            
                p.badge-local.mt-2.mb-0(class="d-flex align-items-center" style="gap: 0.5rem;width:fit-content;") Total Attending Submission: {{submissions.filter(submission => !submission.status).length}}
                p.badge-local.mt-2.mb-0(class="d-flex align-items-center" style="gap: 0.5rem;width:fit-content;") Total Person Attending: {{submissions.filter(submission => !submission.status && submission.totalAttending).reduce((acc,submission) => acc + submission.totalAttending,0)}}
            
                p.badge-local.mt-2.mb-0(class="d-flex align-items-center" style="gap: 0.5rem;width:fit-content;" v-if="event.isSingleDay && event.singleDay")
                    b-icon-cash-coin
                    span $ {{ (Number(event.singleDay.pricePerPerson) + (Number(event.singleDay.pricePerPerson) * Number(event.gst) / 100)).toFixed(2) }}
                b-card-text.badge-local.my-2(style="width:fit-content;") Event Type: {{ event.isSingleDay ? "Single Day" :"Multiple Days" }}
                div(class="d-flex align-items-center" style="gap: 1rem;")
                    b-card-text.m-0.badge-local(class="d-flex align-items-center" style="gap: 0.5rem")
                        b-icon-calendar2-date-fill
                        span {{ event.isSingleDay ? event.singleDay.date : event.multipleEvents[0].date }}
                    b-card-text.badge-local(class="d-flex align-items-center" style="gap: 0.5rem")
                        b-icon-clock-fill
                        //- { "hh": "12", "mm": "05", "A": "AM" }  or 15:00
                        span {{event.isSingleDay ? (typeof event.singleDay.time === 'object' ? `${event.singleDay.time.hh}:${event.singleDay.time.mm} ${event.singleDay.time.A}` : event.singleDay.time) : (typeof event.multipleEvents[0].time === 'object' ? `${event.multipleEvents[0].time.hh}:${event.multipleEvents[0].time.mm} ${event.multipleEvents[0].time.A}` : event.multipleEvents[0].time)}}
                        //- span {{ event.isSingleDay && typeof event.singleDay.time === 'object' ? `${event.singleDay.time.hh}:${event.singleDay.time.mm} ${event.singleDay.time.A}` : event.singleDay.time }}
                        //- span {{ !event.isSingleDay && typeof event.multipleEvents[0].time === 'object' ? `${event.multipleEvents[0].time.hh}:${event.multipleEvents[0].time.mm} ${event.multipleEvents[0].time.A}` : event.multipleEvents[0].time }}
                p.text-start.mt-2.mb-0 Groups
                div.text-details(class="d-flex align-items-center justify-content-start flex-wrap" style="gap: 0.5rem;")
                    span.badge-local(v-for="group in event.selectedGroup" :key="group.id" style="white-space:nowrap;") {{ group.name }}
                div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;")
            //- Single Day
            div(v-if="event.isSingleDay && event.singleDay" class="d-flex flex-column align-items-start" style="gap: 1rem;").w-100
                    div(class="itineraries" v-if="event.singleDay.itineraries.length > 0" style="gap: 1rem;" class="d-flex flex-column align-items-start")
                        b-card-text.my-2.h6 Itineraries
                        div(v-for="itinerary in event.singleDay.itineraries" :key="itinerary.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-icon-arrow-right-circle-fill
                            b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                    div(v-if="event.singleDay.food.lunch.length > 0" style="gap: 1rem;")
                        b-card-text.my-2.h6 Lunch Options
                        div(v-for="lunch in event.singleDay.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-icon-egg-fried
                            b-card-text.m-0 {{ lunch.name }}
                    div(v-if="event.singleDay.food.beverages.length > 0" style="gap: 1rem;")
                        b-card-text.my-2.h6 Beverages
                        div(v-for="beverage in event.singleDay.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-icon-cup-straw
                            b-card-text.m-0 {{ beverage.name }}
                    //- submission details - single day
                        //- multiple [ { "name": "Test Multiple Day Event", "eventID": "0f74b26f-3b6b-ef11-a671-002248ec1beb", "totalAttending": 3, "totalCost": 40, "caregiverName": "Sharon", "clientName": "Neo Leng Neo", "multipleDays": { "whoGoing": [ "Client", "Client", "Caregiver", "Other Persons" ], "client": { "attendingDays": [ "Thursday, September 12, 2024", "Saturday, September 7, 2024" ], "lunchByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "lunchOption": "Chicken" }, { "dayID": "ccbba842-536b-4e0c-afe0-bb80d3d46bf2", "lunchOption": "Chinese Food" } ], "beverageByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "beverageOption": "Water" } ] }, "caregiver": { "attendingDays": [ "Thursday, September 12, 2024" ], "lunchByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "lunchOption": "Chicken" } ], "beverageByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "beverageOption": "Water" } ] }, "otherPersons": [ { "id": "d4335ddd-145c-45bc-b502-92d016d1443c", "name": "Abdullah", "attendingDays": [ "Saturday, September 7, 2024" ], "lunchByDay": [ { "dayID": "ccbba842-536b-4e0c-afe0-bb80d3d46bf2", "lunchOption": "Chinese Food" } ], "beverageByDay": [] } ] } } ]
                        //- single [ { "name": "Test Event Single Day", "eventID": "9aa31e0a-396b-ef11-a671-002248ed82d3", "totalAttending": 1, "totalCost": 0, "caregiverName": "Sharon", "clientName": "Neo Leng Neo", "singleDay": { "whoGoing": [ "Client" ], "client": { "lunch": "", "beverage": "" }, "caregiver": { "lunch": "", "beverage": "" }, "otherPersons": [] } } ]
            
            
            //- Multiple Day
            div(v-if="!event.isSingleDay && event.multipleEvents.length > 0" class="d-flex flex-column" style="gap: 1rem;").w-100
                    div(class="multipleDay-itineraries d-flex flex-column align-items-start w-100" v-if="event.multipleEvents.length > 0" style="gap: 1rem;")
                        b-card-text.my-2.h6 Details about each day
                        div(v-for="day in event.multipleEvents" :key="day.id"
                        class="d-flex flex-column align-items-start shadow-sm rounded-lg p-3"
                        ).my-1.w-100
                            div(class="d-flex flex-column align-items-start" style="gap: 0.5rem;")
                                b-card-text.my-2.h6 {{ day.title }}
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-calendar2-date-fill
                                    b-card-text.m-0 {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                            div(v-if="day.pricePerPerson" class="d-flex align-items-center align-items-start" style="gap: 0.5rem;")
                                b-card-text.m-0(class="d-flex align-items-center" style="gap: 0.5rem")
                                    b-icon-cash-coin
                                    span $ {{ (Number(day.pricePerPerson) + (Number(day.pricePerPerson) * Number(event.gst) / 100)).toFixed(2) }} (After GST)
                            b-card-text.mt-3.h6.text-start Itineraries
                            div(class="d-flex align-items-center " style="gap: 0.5rem;" v-for="itinerary in day.itineraries").my-1
                                b-icon-arrow-right-circle-fill
                                b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                            div(v-if="day.itineraries.length === 0").my-1
                                b-card-text No Itineraries for this day
                            div(v-if="day.food.lunch.length > 0")
                                b-card-text.mt-3.h6.text-start(style="text-align: start;") Lunch Options
                                div(v-for="lunch in day.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;" )
                                    b-icon-egg-fried
                                    b-card-text.m-0 {{ lunch.name }}
                            div(v-if="day.food.beverages.length > 0" style="gap: 1rem;")
                                b-card-text.my-2.h6(style="text-align: start;") Beverages
                                div(v-for="beverage in day.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-cup-straw
                                    b-card-text.m-0 {{ beverage.name }}
        
            div(class="hr" style="width: 100%; border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;")
        b-button(variant="link" @click="showEventDetails = !showEventDetails").mb-2.w-100 {{ showEventDetails ? 'Hide' : 'Show' }} Event Details
        div(v-if="submissions.length > 0 && submissions.some(submission => !submission.status)" class="d-flex flex-column align-items-start mt-3" style="gap: 1rem;").w-100
            
            h5.m-0 Submissions  
            mt-4.text-info.h6(style="text-align: start;") Total Submission: {{submissions.length}}
            mt-4.text-success.h6(style="text-align: start;") Attending (Client): {{submissions.filter(submission => submission.status !== 'Not Attending').length}}
            div(class="w-100 h-auto d-flex flex-column align-items-start rounded-lg shadow-sm p-4" v-for="(submission, index) in submissions" :key="submission.id" v-if="!submission.status")
                    div(class="d-flex align-items-start w-100 justify-content-between")
                        p Submission - {{index + 1}}
                        b-button(variant="outline-primary" @click="showSubmissionDetails(submission)").mb-0 View Submission
                    div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.clientName")
                        b-icon-person-badge
                        b-card-text.m-0(v-if="submission?.clientName")
                            span Client Name: {{ submission?.clientName }}
                    //- div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.caregiverName")
                    //-     b-icon-person-badge
                    //-     b-card-text.m-0(v-if="submission?.caregiverName")
                    //-         span Caregiver Name: {{ submission?.caregiverName }}
                    div(v-if="submission?.totalAttending" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-person-badge
                        b-card-text.m-0(v-if="submission?.totalAttending")
                            span Total Attending: {{ submission?.totalAttending }}
                    

                   
                
                
                    
                    


                
                
        div(v-if="submissions.length > 0 && submissions.filter(submission => submission.status === 'Not Attending').length" class="d-flex flex-column align-items-start" style="gap: 1rem;").w-100
                    b-card-text.mt-4.text-danger.h6(style="text-align: start;") Not Attending (Client): {{submissions.filter(submission => submission.status === 'Not Attending').length}}
                    div(v-for="(submission, index) in submissions" v-if="submission.status && submission.status === 'Not Attending'" :key="submission.id" class="d-flex flex-column align-items-start" style="gap: 0.5rem; border: 1px solid red; padding: 1rem;").my-1.w-100
                        p.text-start Not Attending
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.clientName").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.clientName")
                                span Client Name: {{ submission?.clientName }}
                            b-button(variant="outline-danger" @click="deleteSubmission(submission.id,submission?.eventID,true)").mb-0 Delete Submission
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.caregiverName").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.caregiverName")
                                span Caregiver Name: {{ submission?.caregiverName }}
    
    EditEvent(:eventData="eventData" :showEdit.sync="showEditModal" :update:showEdit.sync="showEditModal" :groups="groups" @updatedEvent="eventUpdated")
    ShowSubmissionDetailsModal(:show.sync="showDetailsSubmission" :submission="selectedSubmission" :event="event" @deleteSubmission="deleteSubmission(selectedSubmission.id,selectedSubmission.eventID)" @close="showDetailsSubmission = false" @submissionDeleted="submissionDeletedFunc" @refresh="refreshEvent")
    AddPeopleEvent(:event="event" :show.sync="showAddPeopleModal"  @close="showAddPeopleModal = false" :eventID="eventData.crb5c_foweventmanagementid" @refresh="refreshEvent")
div(v-else class="d-flex justify-content-center align-items-center w-100 h-100" style="margin-top: 3rem;height: 100vh;gap: 1rem")
    b-spinner(type="grow" variant="primary")                      

</template>
<script>
/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import EditEvent from "./eventModals/EditEvent.vue";
import ShowSubmissionDetailsModal from "./eventModals/ShowSubmissionDetailsModal.vue";
import AddPeopleEvent from "./eventModals/AddPeopleEvent.vue";
export default {
    props: ["eventData", "showDetails", "groups"],
    components: {
        EditEvent,
        ShowSubmissionDetailsModal,
        AddPeopleEvent,
    },
    data() {
        return {
            showAddPeopleModal: false,
            showDetailsSubmission: false,
            loading: false,
            showEditModal: false,
            submissions: [],
            notAttending: [],
            event: {},
            selectedSubmission: {},
            downloading: false,
            showEventDetails: false,
        };
    },
    methods: {
        async downloadExcel() {
            console.log('downloading excel')
            try {
                this.downloading = true;
                const response = await axios.get(
                    `/api/events/v2/getExcel/${this.eventData.crb5c_foweventmanagementid}`,
                    {
                        responseType: "json",
                    }
                );

                if (response.status === 200) {
                    const downloadURL = response?.data?.downloadURL;
                    
                    if (downloadURL) {
                        this.$bvToast.toast(`Excel downloaded successfully`, {
                        title: "Success",
                        autoHideDelay: 5000,
                        appendToast: true,
                        variant: "success",
                    });
                        const a = document.createElement("a");
                        a.href = downloadURL;
                        a.click();
                    } else {
                        this.$bvToast.toast(`Error downloading excel`, {
                            title: "Error",
                            autoHideDelay: 5000,
                            appendToast: true,
                            variant: "danger",
                        });
                    }
                    

                }
            } catch (err) {
                console.log(err);
            }
            finally{
                this.downloading = false;
            }
        },

        submissionDeletedFunc(submissionID) {
            this.submissions = this.submissions.filter(
                (submission) => submission.id !== submissionID
            );
        },
        showSubmissionDetails(submission) {
            this.selectedSubmission = submission;
            this.showDetailsSubmission = true;
        },
        async deleteSubmission(submissionID,eventID,notAttending = false){
            const choice = confirm("Are you sure you want to delete this submission? This action cannot be undone.");
            console.log(submissionID,eventID);
            if(!choice) return;
            if(!submissionID || !eventID) return;
            try{
                const response = await axios.post(`/api/events/v2/deleteSubmission`,{
                    submissionID,
                    eventID,
                    notAttending
                });
                if(response.status === 200){
                    this.$bvToast.toast(`Submission deleted successfully`, {
                        title: "Success",
                        autoHideDelay: 5000,
                        appendToast: true,
                        variant: "success",
                    });
                    this.submissions = this.submissions.filter(submission => submission.id !== submissionID);
                }


            }catch(e){
                console.log(e);
                this.$bvToast.toast(`Error deleting submission`, {
                    title: "Error",
                    autoHideDelay: 5000,
                    appendToast: true,
                    variant: "danger",
                });
            }
        },
        async deleteEvent(){
            const choice = confirm("Are you sure you want to delete this event? This action cannot be undone.");
            if(choice){
                try{
                    this.loading = true;
                    this.$bvToast.toast(`Deleting event...`, {
                        title: "Deleting",
                        autoHideDelay: 5000,
                        appendToast: true,
                        variant: "info",
                    });
                    const response = await axios.patch(`/api/events/v2/deleteEvent/${this.eventData.crb5c_foweventmanagementid}`);
                    if(response.status === 200){
                        this.$emit("deletedEvent");
                        this.event = {};
                        this.$bvToast.toast(`Event deleted successfully`, {
                            title: "Success",
                            autoHideDelay: 5000,
                            appendToast: true,
                            variant: "success",
                        });    
                    }

                }catch(err){
                    console.log(err);
                }
                finally{
                    this.loading = false;
                }
            }
        },  
        async eventUpdated() {
            this.$emit("updatedEvent");
        },
        async refreshEvent() {
            try {
                this.loading = true;
                const response = await axios.get(
                    `/api/events/v2/event/${this.eventData.crb5c_foweventmanagementid}`
                );
                if (response.status === 200) {
                    this.event = JSON.parse(response.data.data.crb5c_eventjson);
                    
                    this.submissions = response.data.data.submissions;
                    this.event['crb5c_invoicegeneration'] = response.data.data.crb5c_invoicegeneration;
                    
                    this.submissions.sort((a, b) => 
                    a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase()));
                    // console.log('this.submissions',this.submissions);
                    // this.$bvToast.toast(`Event refreshed successfully`, {
                    //     title: "Success",
                    //     autoHideDelay: 5000,
                    //     appendToast: true,
                    //     variant: "success",
                    // });
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        eventData: {
            handler(newVal) {
                if (newVal) {
                    // this.event = JSON.parse(newVal.crb5c_eventjson);
                    // if (newVal?.submissions && newVal.submissions.length > 0) {
                    //     this.submissions = newVal.submissions;
                    // } else {
                    //     this.submissions = [];
                    // }
                    this.refreshEvent();
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.badge-local {
    background-color: #8967b3;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
}

.event-details {
    h5 {
        font-size: 1rem;
    }
}
</style>
