<template lang="pug">
b-container(style=" border-radius: 20px; background-color: rgba(255, 255, 255, 0.2); height: 85%;")
    b-row.px-3.px-xl-0.py-3.ml-sm-1.mt-0.mt-xl-5.justify-content-around(style="height: 100%;")
        <!-- left side -->
        b-col.my-3.col-12.col-xl-5(class="justify-content-center align-items-center" )
            b-row(style="height: 100%")
                b-col(class="col-12 justify-content-center align-items-center" style="border: 1px solid black;  border-radius: 20px; background-color: white") 
                    div.d-flex.flex-column.align-items-start.px-5.py-3
                        span(style="font-size: 1.2rem; font-weight: 600;") Pending Clients
                        span.d-none.d-md-inline(style="text-align: start") Pending client as for today {{ dayToday }}
                    div(style="max-height:70vh; overflow-y:auto")
                        div.m-4.p-3.d-flex.flex-column.justify-content-center.align-items-center(
                            style="border-radius:16px; min-height:5rem; background-color: rgb(80 39 107);"
                            v-if="!isFetchingAdminDashboardData && listNewClientRegisteredToday.length !== 0"
                            v-for="(client, index) in listNewClientRegisteredToday"
                        )  
                            span.h5.text-white(
                                v-b-toggle="`button-collapse-item-${index}`"
                                v-b-tooltip.hover 
                                title="Click to view the action"
                                style="cursor: pointer;"
                            ) {{ client.crb5c_no }}

                            b-collapse(:id="`button-collapse-item-${index}`")
                                b-row.mt-2(style="gap: 1rem;")
                                    b-col.col-12
                                        b-button.w-100.font-weight-bold(
                                            variant="info"
                                            @click="$root.redirectToClientPage(client.crb5c_fow_customerid)" 
                                        ) Client page
                                    b-col.col-12
                                        b-button.w-100.font-weight-bold(
                                            variant="info"
                                            @click="$root.redirectToClientAssessment(client.crb5c_fow_customerid)"
                                        ) Assessment page
                                    //- b-col.col-12
                                    //-     b-button.w-100.font-weight-bold(
                                    //-         variant="info"
                                    //-         @click="$root.redirectToClientPage(client.crb5c_fow_customerid)" 
                                    //-     ) Download registration form
                        b-row(
                                v-if="isFetchingAdminDashboardData && listNewClientRegisteredToday.length === 0"
                            )
                            b-col
                                b-spinner(variant="info")
                        b-row(
                                v-if="!isFetchingAdminDashboardData && listNewClientRegisteredToday.length === 0"
                            )
                            b-col
                                span No new client
                        //- router-link(to="/client" class="registered-button" style="text-decoration: none;") {{ listNewClientRegisteredToday.length }} 
        <!-- right side -->
        b-col.my-3.col-12.col-xl-5(style="border: 1px solid black; border-radius: 20px; background-color: rgb(80 39 107);")
            b-row(v-if="listTodaySchedule.length !== 0")
                div.d-flex.flex-column.align-items-start.px-5.py-3
                    router-link(style="font-size: 1.2rem; font-weight: 600; color: white" tag="span" to="/weekly") Today's Schedule ({{ listTodaySchedule.length }})
                    span.text-white.d-none.d-md-inline(style="text-align: start") All sessions as for today {{ dayToday }}
                div(style="max-height:20rem; overflow-y:auto")
                    div.m-4.p-3.bg-white.d-flex.flex-column.justify-content-center.align-items-center(
                        style="border-radius:16px; min-height:8rem"
                        v-for="(schedule, index) in listTodaySchedule"
                        :key="schedule.crb5c_fow_sessionid" 
                        caption="" 
                        img-blank
                    )    
                        h6 {{ schedule.crb5c_sessionname }}
                        b-col.col-12(class="justify-content-center align-items-center") Time : {{ formatDateToTime(schedule.crb5c_date) }} 
                        b-col.col-12(class="justify-content-center align-items-center") Duration : {{ schedule.formattedDuration }} 
            b-row.h-100(
                    v-if="listTodaySchedule.length === 0"
                )
                    b-col.col-12.d-flex.align-items-end.justify-content-center.text-white
                        span.font-weight-bold(style="font-size: 1.2rem;") No schedules for today
                    b-col.col-12.mt-3.text-white
                        Icon(icon="teenyicons:calendar-no-access-outline" width="60")
</template>

<script>
import dayjs from 'dayjs';
import isToday from "dayjs/plugin/isToday";
import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard';
import { mapState } from 'vuex';
import { Icon } from '@iconify/vue2';

dayjs.extend(isToday);

export default {
    components: {
        Icon,
    },
    data() {
        return {
            slide: null,
            schedules: [],
            clientDB: [],
            assessmentDB: [],
            FOWarrayLength: null,
            CIParrayLength: null,
            filteredArrayLength: null,
            // listNewClientRegisteredToday: [],
            dayToday: dayjs().format('DD MMMM YYYY'),
        }
    },
    methods: {
        // async getClientDB() {
        //     try {
        //         console.log("Admin Dashboard Mounted")
        //         const { data: clientData } = await axios.get('/api/admin_dashboard/get_client');
        //         const { data: assessmentData } = await axios.get('/api/admin_dashboard/get_assessment');
        //         const filteredArray = [];

        //         // console.log("clientData: ", clientData);
        //         this.listNewClientRegisteredToday = clientData.filter(item => dayjs(item.createdon).isToday());
        //         // console.log("listNewClientRegisteredToday: ", this.listNewClientRegisteredToday);

        //         // this.clientDB = clientData
        //         //     .map((client) => {
        //         //         const clientCreatedOn = dayjs(client.createdon).format('DD MMM YYYY')
        //         //         const today = dayjs().format('DD MMM YYYY')

        //         //         const monthDiff = dayjs(today, 'DD MMM YYYY').diff(dayjs(clientCreatedOn, 'DD MMM YYYY'), 'month')

        //         //         if (monthDiff >= 0 && monthDiff <= 5) {
        //         //             filteredArray.push(clientCreatedOn)
        //         //             return {
        //         //                 filteredArray,
        //         //             }

        //         //         }
        //         //         return null;
        //         //     })
        //         //     .filter((client) => client !== null);

        //         // console.log('client database: ', this.clientDB)

        //         let FOWarray = 0;
        //         let CIParray = 0;
        //         this.assessmentDB = assessmentData.map((assessment) => {

        //             let clientProgramme = ''
        //             if (assessment.crb5c_eq5d5lform === false) {
        //                 clientProgramme = 'FOW'
        //                 FOWarray++
        //             } else {
        //                 clientProgramme = 'CIP'
        //                 CIParray++
        //             }

        //             let dateAssessment = null
        //             if (assessment.crb5c_dateofassessment === null) {
        //                 dateAssessment = 'No information'
        //             } else {
        //                 dateAssessment = dayjs(assessment.crb5c_dateofassessment).format('DD-MM-YYYY HH:mm')
        //             }

        //             let currentAssessment = ''
        //             if (clientProgramme === 'CIP') {
        //                 if (assessment.crb5c_cip1stsession === null && assessment.crb5c_cip2ndsession === null) {
        //                     currentAssessment = '14th Session Week'
        //                 } else {
        //                     currentAssessment = '6 Months'
        //                 }
        //             }
        //             if (clientProgramme === 'FOW') {
        //                 currentAssessment = '1 Year'
        //             }

        //             return {
        //                 ...assessment,
        //                 FOWarray,
        //                 CIParray,
        //                 currentAssessment: currentAssessment,
        //                 clientProgramme: clientProgramme,
        //                 dateAssessment: dateAssessment
        //             }
        //         })
        //         const FOWarrayLength = FOWarray
        //         this.FOWarrayLength = FOWarrayLength

        //         const filteredArrayLength = filteredArray.length;
        //         this.filteredArrayLength = filteredArrayLength
        //         console.log('filteredArray length: ', filteredArrayLength);
        //         console.log('assessment database: ', this.assessmentDB);

        //     } catch (error) {
        //         console.log(error)
        //         return []
        //     }
        // },
        formatDateToTime(rawDateISOstring){
            return dayjs(rawDateISOstring).format("hh:mm");
        },
        getSchedules(){
            this.schedules = [
                {
                    id: 1,
                },
                {
                    id: 2,
                },
            ];
        },



    },
    computed: {
        ...mapState({
            listNewClientRegisteredToday: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listNewClientRegisteredToday),
            listUpcomingAssessment: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listUpcomingAssessment),
            listTodaySchedule: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listTodaySchedule),
            isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
        listFOWclientAssessment(){
            return this.listUpcomingAssessment.filter(item => Boolean(!item.crb5c_eq5d5lform));
        },
        listCIPclientAssessment(){
            return this.listUpcomingAssessment.filter(item => Boolean(item.crb5c_eq5d5lform));
        },
        
    },
    async mounted() {
        this.getSchedules();

    }
}

</script>

<style>
.registered-button,
.upcoming-button {
    color: black;
    font-weight: 700;
    font-size: 2.75rem;
    color: inherit;
    padding: 10px
}

a>.carousel-control-prev-icon,
.carousel-control-next-icon {
    opacity: 1;
}</style>