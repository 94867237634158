<template lang="pug">
b-container(fluid style="height: 100vh;")
    b-row(class="justify-content-between align-items-center" style="height: 15%;" cols="12")
        b-img(class="my-4 ml-4" :src="DSG_logo", alt="DSG Logo")
        small.mx-4.font-weight-bold()
            | {{displayname.value}} 
            b-button.mx-3.text-danger.bg-transparent.border-0(@click='logout' v-if="displayname.value") Logout
                Icon( icon="material-symbols:logout" width="20").mx-3
    
    //--phone screen--//
    b-row.mx-4.d-md-none.d-inline(v-if="displayname.value")
        b-col.col-12
            b-list-group#buttonSection.shadow.border
                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(button @click="moveToAnnouncementsPage")
                    Icon(icon="ion:megaphone-outline" width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Announcement
                        small.font-italic View all announcement details
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
                //- b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(button @click="moveToFowMessagePage")
                //-     BIconChatLeftTextFill(width="30").mx-3
                //-     div.w-100
                //-         h6.font-weight-bold FOW Chat
                //-         small.font-italic View Caregiver Messages
                //-         div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
                //-             v-if="countTotalCaregiverNewMessage !== 0"
                //-             style="width: 1.8rem; height: 1.8rem; font-size: 0.7rem; position: absolute; background-color: red; top: -1%;; right: -1%; border-radius: 1rem;"
                //-         ) 
                //-             span {{ countTotalCaregiverNewMessage }}
                //-     b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
                
                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="moveToEventsPage")
                    BIconCalendar2EventFill(width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Event
                        small.font-italic View all events
                        div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
                            v-if="indicatorCountCaregiverCashOrChequeNotAcknowledge && indicatorCountCaregiverCashOrChequeNotAcknowledge.length !== 0"
                            style="width: 1.8rem; height: 1.8rem; font-size: 0.7rem; position: absolute; background-color: red; top: -1%; right: -1%; border-radius: 1rem;"
                        ) 
                            span {{ indicatorCountCaregiverCashOrChequeNotAcknowledge.reduce((total, item) => total + item.caregivers.length, 0) }}
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="moveToAdminHomepage")
                    Icon(icon="ic:round-dashboard" width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Admin Dashboard
                        small.font-italic Access to dashboard
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="moveToMobileConfig")
                    Icon(icon="ant-design:setting-filled" width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Caregiver Configuration
                        small.font-italic Configure mobile app settings
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="goToModularForm")
                    Icon(icon="mdi:planner-outline" width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Modular Form
                        small.font-italic Modular Form
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

                b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="goToRegistrationForm")
                    Icon(icon="mdi:planner-outline" width="30").mx-3
                    div.w-100
                        h6.font-weight-bold Registration Form Submissions
                        small.font-italic Registration Form Submissions
                    b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")


                //- b-list-group-item#buttonList.py-4.px-3.text-dark.d-flex.align-items-center(tag="b-list-group-item" @click="moveToCarePlan")
                //-     Icon(icon="mdi:planner-outline" width="30").mx-3
                //-     div.w-100
                //-         h6.font-weight-bold Care Plan
                //-         small.font-italic Check clients care plan
                //-     b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
    //--phone screen--//


    //--other screen--//
    b-row(class="d-none d-md-flex justify-content-center align-items-start gap-3 px-5 px-md-5 px-lg-4" style="height: 70%; gap: 1.5rem;" v-if="displayname.value")
        b-col(class="h-75 d-flex flex-column col-12 col-md-4 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
            b-row(class="h-100" style="gap: 10%;")
                b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center cursor-pointer" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer;`" cols="12" @click="moveToAnnouncementsPage")
                    Icon(class="h-50 w-50 text-white" icon="ion:megaphone-outline")
                b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
                    span(class="")
                    | Announcements
        //- b-col(class="h-75 d-flex flex-column col-12 col-md-4 col-lg-3 col-xl-2" style=" margin-top: 5rem; position: relative;")
        //-     b-row(class="h-100" style="gap: 10%;")
        //-         b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="moveToFowMessagePage")
        //-             BIconChatLeftTextFill(class="h-50 w-50 text-white")
        //-         b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
        //-             span(class="")
        //-             | FOW Chat
        //-         div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
        //-             v-if="countTotalCaregiverNewMessage !== 0"
        //-             style="width: 1.8rem; height: 1.8rem; font-size: 0.7rem; position: absolute; background-color: red; top: -1%;; right: -1%; border-radius: 1rem;"
        //-         ) 
        //-             span {{ countTotalCaregiverNewMessage }}
        b-col(class="h-75 d-flex flex-column col-12 col-md-4 col-lg-3 col-xl-2" style=" margin-top: 5rem; position: relative;")
            b-row(class="h-100" style="gap: 10%;")
                b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="moveToEventsPage")
                    BIconCalendar2EventFill(class="h-50 w-50 text-white")
                b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
                    span(class="")
                    | Events
                div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
                    v-if="indicatorCountCaregiverCashOrChequeNotAcknowledge && indicatorCountCaregiverCashOrChequeNotAcknowledge.length !== 0"
                    style="width: 1.8rem; height: 1.8rem; font-size: 0.7rem; position: absolute; background-color: red; top: -1%;; right: -1%; border-radius: 1rem;"
                ) 
                    span {{ indicatorCountCaregiverCashOrChequeNotAcknowledge.reduce((total, item) => total + item.caregivers.length, 0) }}
        b-col(class="h-75 d-flex flex-column col-md-3 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
            b-row(class="h-100 flex-row" style="gap: 10%;")
                b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="moveToAdminHomepage")
                    Icon(icon="ic:round-dashboard" height="50%" color="white")
                b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
                    span(class="")
                    | Admin Dashboard
        b-col(class="h-75 d-flex flex-column col-md-3 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
            b-row(class="h-100 flex-row" style="gap: 10%;")
                b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="moveToMobileConfig")
                    Icon(icon="ant-design:setting-filled" height="50%" color="white")
                b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
                    span(class="")
                    | Caregiver App Configuration
    
        b-col(class="h-75 d-flex flex-column col-md-3 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
            b-row(class="h-100 flex-row justify-content-center" style="gap: 10%;")
                b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="goToModularForm")
                    Icon(icon="mdi:planner-outline" height="50%" color="white")
                b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
                    span(class="")
                    | Modular Form
        //- b-col(class="h-75 d-flex flex-column col-md-3 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
        //-     b-row(class="h-100 flex-row justify-content-center" style="gap: 10%;")
        //-         b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="goToRegistrationForm")
        //-             Icon(icon="mdi:planner-outline" height="50%" color="white")
        //-         b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
        //-             span(class="")
        //-             | Registration Form Submissions



        //- b-col(class="h-75 d-flex flex-column col-md-3 col-lg-3 col-xl-2" style=" margin-top: 5rem;")
        //-     b-row(class="h-100 flex-row" style="gap: 10%;")
        //-         b-col(class="h-50 h-sm-50 h-lg-50 h-xxl-75 d-flex align-items-center justify-content-center" :style="`background-color: ${buttonColor}; border-radius: 20px; cursor: pointer; display: relative;`" cols="12" @click="moveToCarePlan")
        //-             Icon(icon="mdi:planner-outline" height="50%" color="white")
        //-         b-col(class="font-weight-bold" style="height: 40%; font-size: 1.5rem;" cols="12")
        //-             span(class="")
        //-             | Care Plan
    //--other screen--//   
    div(v-if="!displayname.value" class="d-flex justify-content-center align-items-center" style="height: 85%;")
        b-spinner(type="grow" variant="primary")     
</template>

<script>
// import authModule from '../store/authenticator';
import DSG_logo from '@/assets/Images/DSG_logo.png';
import { BIconChatLeftTextFill, BIconCalendar2EventFill } from 'bootstrap-vue';  
import {Icon} from '@iconify/vue2';
import { MESSAGE } from '@/store/ModuleName';
import { mapState, mapGetters } from 'vuex';
import authModule from '../store/authenticator';
import { EVENTS_NAMESPACE, GETTERS } from "../store/Modules/Events/types-events";

// import axios from 'axios';

export default {
    name: 'HomePage',
    components: {
        BIconChatLeftTextFill,
        BIconCalendar2EventFill,
        Icon,
    },
    
    data() {
        return {
            isLocal: false,
            DSG_logo,
            buttonColor: 'rgb(118, 80, 137)',
            logindata: { ready: false },
            msalApp: null,
            isAdmin: false,
            promptLogin: false,
        }
    },
    methods:{
        async logout(){
            await authModule.removeAccount();
        },
        goToModularForm(){
            
            window.open('https://dsg-form.pages.dev/', '_blank');
        },
        goToRegistrationForm(){
            
            window.open('https://dsg-form.pages.dev/form-response/b0ebf55e-f927-ef11-840b-6045bd58192c/none', '_blank');
        },
        askAndProceed(){
            const checkNotificationPromise = () => {
                try {
                    Notification.requestPermission().then(result => {
                        if(result === "granted"){
                            console.log("Notification permission granted");
                            return true;
                        }
                        if(result === "denied"){
                            console.error("Notification permission denied");
                            return false;
                        }
                    });
                } catch (e) {
                    return false;
                }
                
                return true;
            }
            
            if (checkNotificationPromise()) {
                Notification.requestPermission().then(() => {

                });
            }

            // this.$router.push({path: '/home'});
        },
        moveToAnnouncementsPage(){
            this.$router.push({path: '/announcements'});
        },
        moveToRemindersPage(){
            this.$router.push({path: '/reminders'});
        },
        moveToFowMessagePage(){
            this.$router.push({path: '/chat'});
        },
        moveToEventsPage(){
            this.$router.push({path: '/events'});
        },
        moveToAdminHomepage(){
            this.$router.push({path: '/admindashboard'})
        },
        moveToMobileConfig(){
            this.$router.push({path: '/appconfiguration'})
        },
        moveToCarePlan(){
            this.$router.push({path: '/careplan'})
        },
        moveToCarePlanAdmin(){
            this.$router.push({path: '/careplanadmin'})
        },
        setTheWindowWidth(){
            this.windowWidth = window.innerWidth;
        },
        // async test() {
        //     const {data} = await axios.post('/api/announcement', {});
        //     console.log(data)
        // },
    },
    computed: {
        ...mapState({
            countTotalCaregiverNewMessage: state => (state[MESSAGE].countTotalCaregiverNewMessage),
        }),
        ...mapGetters({
            [GETTERS.INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE]: `${EVENTS_NAMESPACE}/${GETTERS.INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE}`
        }),
        displayname() {
        return authModule.displayname;
        },
        username() {
        return authModule.username;
        },

    },
    async mounted(){
        // console.log('home init')
        // await authModule.init();
        // await this.$root.init();
        this.isLocal = location.host.includes("localhost");
        this.askAndProceed();
    }
    

}
</script>
<style lang="scss">
    #buttonSection{
        border-radius:12px
    }

    #buttonList:active{
        background-color: #26263e;
        color:white !important;
        transition-duration: 0.25s;
    }
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {  }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {  }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        .h-lg-50{
            height: 50%;
            max-height: 50%;
        }
    }

    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {}

    // XX-Large devices (larger desktops, 1400px and up)
    @media (min-width: 1400px) { 
        .h-xxl-75{
            height: 75%;
            max-height: 75%;
        }
    }

</style>